@tailwind base;

@tailwind components;

@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');

::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: grey;
  border-radius: 5px;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  overscroll-behavior-y: contain;
  /*width: 100vw;*/
  /*height: 100vh;*/
  background-repeat: no-repeat;
  background-position: center;
}
.headerBackground {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-image: linear-gradient(to bottom, #f6f6f6 36%, rgba(255, 255, 255, 0) 100%);
}

@font-face {
  font-family: 'WorkSans';
  font-display: swap;
  src: url('./assets/fonts/WorkSans-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'WorkSansRegular', sans-serif;
  font-display: swap;
  src: url('./assets/fonts/WorkSans-VariableFont_wght.ttf') format('opentype');
}

.fontFamily-WorkSans {
  font-family: 'WorkSans', sans-serif;
}
.fontFamily-WorkSans-regular {
  font-family: 'WorkSansRegular', sans-serif;
}

/*Spinner styles*/

.wrapper-lds-spinner {
  /*background: rgba(0, 0, 0, 0.2);*/
  width: 100%;
  margin: auto;
  height: 100vh;
}

.lds-spinner {
  color: #000;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*MinSpinner styles*/

.sk-fading-circle {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  position: relative;
}

.sk-fading-circle .sk-circle,
.sk-fading-circle .sk-circle-lightgrey,
.sk-fading-circle .sk-circle-gray {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle.sk-circle-lightgrey:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: lightgrey;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle-gray:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: gray;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/*ladies checkbox*/
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -3px;
  bottom: -3px;
  background-color: #f1f1f1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

input:checked + .slider {
  background-color: #00ccbf;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ccbf;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*input range*/

/* The inputRange itself */
.inputRange {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 1px; /* Specified height */
  background: #979797; /* Grey background */
  border: solid 1px #979797;
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.inputRange:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.inputRange::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 14px; /* Set a specific slider handle width */
  height: 14px; /* Slider handle height */
  border-radius: 50%;
  cursor: pointer; /* Cursor on hover */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #030303;
}

.inputRange::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #030303; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.picker-panel {
  z-index: 1000 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.year-picker,
.input-wrapper,
.year-input {
  width: 100%;
}

.year-picker {
  margin-top: 16px;
}

.year-picker-signUp {
  width: 100%;
  margin-top: 16px !important;
}

.year-picker-signUp > div > input {
  font-size: 1rem;
  line-height: 1.5rem !important;
  padding: 10.5px 16px !important;
  background: #f3f4f6;
}

.year-picker-edit {
  margin-top: 0;
  border: 1px solid #fff !important;
  background: #fff;
}

.year-picker-edit > div > input {
  padding: 0 !important;
  width: 144px !important;
}

.year-input {
  padding: 8px 16px;
  background: #f3f4f6;
  border: 1px solid #f3f4f6 !important;
  font-size: 1rem;
}

.year-input-edit {
  padding: 0 !important;
  background: #fff !important ;
  border: 1px solid #fff !important;
  font-size: 0.9rem;
  max-width: 9rem;
  color: #000;
}

.showAnswer {
  display: block;
}

.faqItem > p {
  margin-bottom: 10px;
}

.menuCalcHeight {
  height: calc(100% - 88px);
}

.rideListForDriverCalcHeight {
  height: calc(100vh - 116px);
}

.rideListForRiderCalcHeight {
  height: calc(100vh - 60px);
}

/* Checkbox custom style */
.checkbox-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
  height: 15px;
  width: 15px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a black background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* .termsItemContent > p {
  margin-bottom: 5px;
  text-indent: 1rem;
  text-align: justify;
}

.termsItemContent > ul > li {
  margin-bottom: 3px;
  text-indent: 0.5rem;
  text-align: justify;
} */

.list-disc {
  list-style: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 20px;
}

.list-disc > li {
  text-indent: 0;
}

.aboutUsContent > div {
  margin-bottom: 40px;
}

.aboutUsContent > div > p {
  padding: 0 20px;
}

.safetyContent > div {
  margin-bottom: 30px;
}

.safetyContent > div > h2 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

.safetyContent > div > div > h3,
.safetyContent > div > h3 {
  /*margin-bottom: 5px;*/
  font-weight: bold;
  font-size: 18px;
}

.safetyContent > div > div > h4,
.safetyContent > div > h4 {
  /*margin-bottom: 5px;*/
  font-weight: bold;
  font-size: 16px;
  /*text-indent: 3%;*/
}

.safetyContent > div > div {
  margin-bottom: 10px;
}

.safetyContent > div > div > p {
  margin-bottom: 5px;
}
